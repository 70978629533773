@import '~antd/dist/antd.less';

.ant-table table { font-size: 20px; }
.ant-table-body {
  background-color: white;
}
.ant-table-thead > tr > th {
  background-color: #9AC949 !important;
  color: white;
  border: 1px solid white;
}

.ant-table-content {
  padding: 20px;
  background-color: white;
  border-radius: 20px;
}

.ant-card {
  border-radius: 20px;
}

.ant-col {
  background-color: transparent;
}

.ant-layout-sider-trigger {
  background-color: #C7B299;
}

.ant-layout-header {
  background-color: rgba(255, 255, 255, 0.7);
}

.ant-menu-item > a {
  color: #998675;
}

.slip-wrapper {
  display: grid;
  grid-gap: 0px;
  grid-auto-columns: 90px;
  grid-template-rows: 50px 30px 50px 30px 50px 30px 50px 30px 50px 30px;
  background-color: #fff;
  color: #444;
  width: 100%;
}

.slip-box {
  border: 1px solid black !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 6px;
  text-align: center;;
}

.slip-1 {
  grid-column: 1;
  grid-row-start: 1;
  grid-row-end: 5;
}
.slip-12 {
  grid-column: 1;
  grid-row-start: 5;
  grid-row-end: 9;
}
.slip-23 {
  grid-column: 1;
  grid-row-start: 9;
  grid-row-end: 11;
}
.slip-31 {
  grid-column-start: 1;
  grid-column-end: 9;
  grid-row: 11;
}

.ant-layout-sider-zero-width-trigger {
  z-index: 2;
}

h1 {
  @media screen and (max-width: 767px) {
    font-size: 20px !important;
  }
}

h2 {
  @media screen and (max-width: 767px) {
    font-size: 16px !important;
  }
}

h3 {
  @media screen and (max-width: 767px) {
    font-size: 16px !important;
  }
}

h4 {
  @media screen and (max-width: 767px) {
    font-size: 14px !important;
  }
}
@font-family: Mitr, sans-serif;@text-color: #998675;@heading-color: #998675;@primary-color: #9AC949;
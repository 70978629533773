html {
  font-family: 'Mitr', sans-serif !important;
}
.ant-typography {
  font-family: 'Mitr', sans-serif !important;
}

@media print {
  @page { margin: 0; }
  body { margin: 1.6cm; }
  #header, #footer {display:none;}
}

.font-bold {
  font-weight: bold;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ql-editor {
  min-height: 250px;
}